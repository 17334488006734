<template>
  <v-card>
    <v-card-title class="text-h5"> Model: {{ item.name }} </v-card-title>
    <v-card-text>
      <v-simple-table>
        <template v-slot:default>
          <thead>
            <tr>
              <th class="text-left">
                Name
              </th>
              <th class="text-left">
                Value
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(value, name, index) in item" :key="index">
              <td>{{ name }}</td>
              <td>{{ value }}</td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  props: ["item"],
  methods: {
    closeClick() {
      this.$emit("submit", "close");
    },
  },
};
</script>
