<template>
  <div style="height: 100%">
    <!-- loading animation -->
    <!-- <template v-if="loading">
      <v-container fluid style="height: 100%">
        <v-row align="center" justify="center" style="height: 100%">
          <v-col class="text-center">
            <v-progress-circular
              indeterminate
              color="primary"
            ></v-progress-circular>
          </v-col>
        </v-row>
      </v-container>
    </template> -->

    <!-- data content -->
    <template>
      <!-- toolbar -->
      <v-toolbar dense flat>
        <v-toolbar-title>{{ $t("drawer.Models") }}</v-toolbar-title>
        <v-btn text color="primary" @click="onRefresh">
          <v-icon>mdi-refresh</v-icon>
          <span>Refresh</span>
        </v-btn>
      </v-toolbar>
      <v-divider></v-divider>

      <v-container>
        <v-row>
          <v-col>
            <v-card-title class="pt-0 px-0">
              <v-spacer></v-spacer>
              <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="Search"
                single-line
                hide-details
                class="shrink"
              ></v-text-field>
            </v-card-title>
            <v-data-table
              dense
              :headers="headers"
              :items="models"
              :search="search"
              item-key="id"
              class="elevation-1"
              :loading="loading"
              :loading-text="$t('loadingInfo')"
              :items-per-page="-1"
              hide-default-footer
              show-expand
              single-expand
            >
              <template v-slot:item.status="{ item }">
                <div v-if="typeof item.status === 'number'">
                  <v-progress-linear
                    v-model="item.status"
                    height="20"
                    style="pointer-events: none"
                  >
                    <strong>{{ Math.ceil(item.status) }}%</strong>
                  </v-progress-linear>
                </div>
                <div v-else>
                  {{ item.status }}
                </div>
              </template>
              <template v-slot:item.actions="{ item }">
                <div class="d-flex">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        icon
                        color="indigo"
                        v-bind="attrs"
                        v-on="on"
                        @click="showModelDetail(item)"
                      >
                        <v-icon>mdi-eye</v-icon>
                      </v-btn>
                    </template>
                    <span>{{ $t("view") }}</span>
                  </v-tooltip>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        icon
                        color="blue"
                        v-bind="attrs"
                        v-on="on"
                        @click="onStartEditDialog(item)"
                      >
                        <v-icon>mdi-square-edit-outline</v-icon>
                      </v-btn>
                    </template>
                    <span>{{ $t("edit") }}</span>
                  </v-tooltip>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        icon
                        color="primary"
                        v-bind="attrs"
                        v-on="on"
                        @click="showModelResult(item)"
                      >
                        <v-icon>mdi-chart-line</v-icon>
                      </v-btn>
                    </template>
                    <span>{{ $t("drawer.Results") }}</span>
                  </v-tooltip>
                  <!-- <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        icon
                        color="error"
                        v-bind="attrs"
                        v-on="on"
                        @click="onDeleteModel(item)"
                      >
                        <v-icon>mdi-trash-can-outline</v-icon>
                      </v-btn>
                    </template>
                    <span>{{ $t("delete") }}</span>
                  </v-tooltip> -->
                </div>
              </template>
              <template v-slot:expanded-item="{ item }">
                <td :colspan="12">
                  <div>{{ item.description }}</div>
                </td>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </v-container>
    </template>

    <!-- Update dataset group info -->
    <v-dialog v-model="dialogModel" persistent max-width="600px">
      <v-card>
        <v-card-title>
          <span class="text-h5"> Edit Model </span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12">
                <form class="mt-5">
                  <v-text-field
                    v-model="model.name"
                    label="Model name"
                    :error-messages="modelNameErrors"
                    required
                    @change="$v.model.name.$touch()"
                    @blur="$v.model.name.$touch()"
                    dense
                  ></v-text-field>

                  <v-textarea
                    label="Descripstion"
                    v-model="model.description"
                    :error-messages="modelDescriptionErrors"
                    @change="$v.model.description.$touch()"
                    @blur="$v.model.description.$touch()"
                    rows="1"
                    row-height="20"
                  ></v-textarea>
                </form>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="onCloseModelDialog">
            Close
          </v-btn>
          <v-btn
            color="blue darken-1"
            text
            @click="onEditModel"
            :disabled="onUpdateBtnLocked"
          >
            Update
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import ModelDetail from "@/views/models/ModelDetail.vue";
import data from "@/models/data";
import _ from "lodash";
import { formatTimeToClientTimezone } from "@/utils";
import { required, maxLength } from "vuelidate/lib/validators";

export default {
  name: "ModelList",
  data: () => ({
    search: "",
    models: [],
    pollingInterval: null,
    dialogModel: false,
    item: null,
    model: {
      name: null,
      description: null,
    },
    onUpdateBtnLocked: false,
  }),
  validations: {
    model: {
      name: {
        required,
        maxLength: maxLength(32),
      },
      description: {
        maxLength: maxLength(512),
      },
    },
  },
  computed: {
    loading() {
      return this.$store.state.loader.loading;
    },
    headers() {
      return [
        { text: this.$t("modelName"), value: "name" },
        { text: this.$t("projectName"), value: "dataset_group_name" },
        // { text: this.$t("targetTrait"), value: "y_var" },
        { text: this.$t("createdTime"), value: "created_at" },
        { text: this.$t("endedTime"), value: "ended_at" },
        { text: this.$t("trainingTime"), value: "training_time" },
        { text: this.$t("status"), value: "status" },
        { text: this.$t("action"), value: "actions", sortable: false },
      ];
    },
    // models() {
    //   return this.$store.state.select.models.filter(function (item) {
    //     if (item && item.UID) {
    //       return item.UID[0] !== "Default";
    //     }
    //   });
    // },
    modelNameErrors() {
      const errors = [];
      if (!this.$v.model.name.$dirty) return errors;
      !this.$v.model.name.required && errors.push(this.$t("required"));
      console.log(this.$v.model.name.$params);
      !this.$v.model.name.maxLength &&
        errors.push(
          `Must be at most ${this.$v.model.name.$params.maxLength.max}`
        );
      return errors;
    },
    modelDescriptionErrors() {
      const errors = [];
      if (!this.$v.model.description.$dirty) return errors;
      !this.$v.model.description.maxLength &&
        errors.push(
          `Must be at most ${this.$v.model.description.$params.maxLength.max}`
        );
      return errors;
    },
  },
  methods: {
    async getModels() {
      // await this.$store.dispatch("fetchModel");

      let newModels = "";
      const res = await data.getModels();
      let resDatasetGroups = await data.getDatasetGroups();
      const datasetGroups = resDatasetGroups.data.DatasetGroup;
      res.data.models.forEach((model) => {
        if (model.created_at) {
          model.created_at = formatTimeToClientTimezone(
            model.created_at.split(".")[0]
          );
        }
        if (model.ended_at) {
          model.ended_at = formatTimeToClientTimezone(
            model.ended_at.split(".")[0]
          );
        } else {
          model.ended_at = "-";
        }
        const datasetGroup = datasetGroups.find((datasetGroup) => {
          return datasetGroup.id === model.dataset_group_id;
        });
        model["dataset_group_name"] = datasetGroup.name;
        if (
          (model.is_done && model.progress === 100) ||
          model.progress === 10000
        ) {
          model["status"] = "Done";
        } else if (model.step && model.step.startsWith("Failed")) {
          // model["status"] = model.step;
          model["status"] = `Error: ${model.step}`;
        } else if (model.step === "Pending") {
          model["status"] = "Pending";
        } else if (model.progress !== 100) {
          model["status"] = model.progress;
        } else {
          // error
          model["status"] = model.step;
        }
      });

      const datasetName = this.$route.query.datasetName;
      if (datasetName !== undefined) {
        newModels = res.data.models.filter((model) => {
          return model.dataset_group_name == datasetName;
        });
        this.models = _.orderBy(newModels, ["id"], ["desc"]);
      } else {
        this.models = _.orderBy(res.data.models, ["id"], ["desc"]);
      }
    },
    showModelDetail(item) {
      this.$dialog.show(ModelDetail, {
        item: item,
      });
    },
    async onDeleteModel(item) {
      let res = await this.$dialog.error({
        text: `${this.$t("deleteInfo")} ${item.model_name} ?`,
        title: this.$t("warning"),
        actions: {
          false: this.$t("no"),
          true: {
            color: "red",
            text: this.$t("yes"),
          },
        },
      });
      this.$store.dispatch("loader/show");
      if (res === true) {
        await this.$store.dispatch("onDeleteModel", {
          M_UID: item.M_UID,
        });
        this.getModels();
      }
      this.$store.dispatch("loader/hide");
    },
    showModelResult(item) {
      console.log("showModelResult", item);
      if (item.status === "Done") {
        this.$router.push({
          path: "/results",
          query: {
            id: `${item.id}`,
            datasetGroupId: `${item.dataset_group_id}`,
          },
        });
      } else {
        this.$dialog.message.info(this.$t("modelTraning"), {
          position: "top",
        });
      }
    },
    async onRefresh() {
      this.$store.dispatch("loader/show");
      await this.getModels();
      this.$store.dispatch("loader/hide");
    },
    formatTimeToClientTimezone,
    onStartEditDialog(item) {
      this.dialogModel = true;
      this.item = item;
      this.model.name = this.item.name;
      this.model.description = this.item.description;
    },
    async onEditModel() {
      this.onUpdateBtnLocked = true;
      this.$v.$touch();
      if (this.$v.$invalid) {
        // valid
        console.log("this.$v.$invalid", this.$v.$invalid);
        if (this.$v.model.$invalid) {
          console.log("this.$v.model", this.$v.model.$invalid);
        }
      } else {
        const payload = {
          model_id: this.item.id,
          name: this.model.name,
          description: this.model.description,
        };
        try {
          const res = await data.updateModel(payload);
          console.log("res", res);
          if (res.status !== 200) {
            this.$dialog.message.warning("Upadate project error.", {
              position: "top",
            });
          } else if (res.status === 200) {
            this.onCloseModelDialog();
            this.$dialog.message.success("Upadate project successfully.", {
              position: "top",
            });
            this.getModels();
          } else {
            this.$dialog.message.error(this.$t("systemError"), {
              position: "top",
            });
          }
        } catch (error) {
          console.error(error);
        }
      }
      this.onUpdateBtnLocked = false;
    },
    onCloseModelDialog() {
      this.dialogModel = false;
    },
  },
  created() {
    this.getModels();
    this.pollingInterval = setInterval(this.onRefresh, 30000); // Run every 30 sec
  },
  beforeDestroy() {
    // Clear the interval before the component is destroyed
    clearInterval(this.pollingInterval);
  },
};
</script>

<style scope>
.v-data-table-header th {
  white-space: nowrap;
}
</style>
